// 
// general.scss
//

html {
  position: relative;
  min-height: 100%;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none !important;
}

.register-facebook-icon{
  margin-right: unset !important;
  font-size: 28px;
}
.register-google-icon{
  margin-top: -6px;
}