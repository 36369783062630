// 
//  chat.scss
// 

 /* Search */
 .chat-search {
    // height: $topbar-height;
    display: table;
    width: 100%;
    .chat-search-box {
        display: table-cell;
        vertical-align: middle;
        position: relative;
    }
    .form-control {        
        padding: 0;
        box-shadow: none;
        // background-color: $chat-secondary-user-bg !important;
        border-color: $chat-secondary-user-bg !important;
        &::placeholder {
            color: darken($gray-500, 10%);
        }
    }

    [type="search"]::-webkit-search-cancel-button {
        -webkit-appearance: none;
        appearance: none;
        height: 10px;
        // padding-right: 20px;
        width: 10px;
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjEyMy4wNXB4IiBoZWlnaHQ9IjEyMy4wNXB4IiB2aWV3Qm94PSIwIDAgMTIzLjA1IDEyMy4wNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIzLjA1IDEyMy4wNTsiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTEyMS4zMjUsMTAuOTI1bC04LjUtOC4zOTljLTIuMy0yLjMtNi4xLTIuMy04LjUsMGwtNDIuNCw0Mi4zOTlMMTguNzI2LDEuNzI2Yy0yLjMwMS0yLjMwMS02LjEwMS0yLjMwMS04LjUsMGwtOC41LDguNQ0KCQljLTIuMzAxLDIuMy0yLjMwMSw2LjEsMCw4LjVsNDMuMSw0My4xbC00Mi4zLDQyLjVjLTIuMywyLjMtMi4zLDYuMSwwLDguNWw4LjUsOC41YzIuMywyLjMsNi4xLDIuMyw4LjUsMGw0Mi4zOTktNDIuNGw0Mi40LDQyLjQNCgkJYzIuMywyLjMsNi4xLDIuMyw4LjUsMGw4LjUtOC41YzIuMy0yLjMsMi4zLTYuMSwwLTguNWwtNDIuNS00Mi40bDQyLjQtNDIuMzk5QzEyMy42MjUsMTcuMTI1LDEyMy42MjUsMTMuMzI1LDEyMS4zMjUsMTAuOTI1eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=);
        background-size: 10px 10px;
      }

    .btn {
        box-shadow: none !important;
        border-color: $chat-secondary-user-bg !important;
        border-right-color: #FFFFFF !important;
        // border-left: red !important;
        // background-color: $chat-secondary-user-bg;
        // color: $gray-600;
        color: #000000;
    }
}

.user-status {
    position: absolute;
    height: 11px;
    width: 11px;
    background-color: $gray-400;
    border: 2.5px solid $white;
    border-radius: 50%;
    bottom: 0;
    left: 35px;
}

.video-call{
    background-image: url("../../../images/users/chat.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 450px;
    position: relative;    
    .video-call-head{
        img{
            height: 4rem;
            width: 4rem;
        }
    }
    .video-call-action{
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        padding: 60px 0 16px;
        background: linear-gradient(to top, $black 0, rgba($black, 0) 90%);
    }
}

.voice-call{
    height: 450px;
    position: relative;
    .voice-call-head{
        img{
            width: 6rem;
            height: 6rem;
        }
    }
    .voice-call-action{
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
    }
}

.conversation-list {
    list-style: none;
    padding: 0 15px;
    outline: none;
    li {
        margin-bottom: 20px;
        .conversation-actions {
            display: none;
        }
        &:hover{
            .conversation-actions {
                display: block;
            }
        }
                
    }
    .ctext-wrap {
        background: $chat-secondary-user-bg;
        color: $gray-600;
        border-radius: 3px;
        display: inline-block;
        padding: 7px 12px;
        position: relative;
        i {
            display: block;
            font-size: 12px;
            font-style: normal;
            font-weight: bold;
            position: relative;
        }
        p {
            margin: 0;
            padding: 0;
        }
        &:after {
            left: -11px;
            top: 0;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-width: 6px;
            margin-right: -1px;
        }
    }
    .conversation-text {
        float: left;
        font-size: 13px;
        margin-left: 12px;
        width: 80%;
    }
    .odd {
        .conversation-text {
            float: right !important;
            text-align: right !important;
            width: 70% !important;
        }
        .ctext-wrap {
            background-color: $chat-primary-user-bg;
            color: $gray-700;
            &:after {
                border-color: transparent;
                right: -10px;
                left: auto;
            }
        }
    }
}