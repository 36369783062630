//
// reboot.scss
//
body {
    padding-right: 0px !important;
    padding-left: 0px !important;
    &.loading {
        visibility: hidden;
        opacity: 0;
    }
    background-color: #FFFFFF;
}

// Forms
label {
    font-weight: 400;
    margin-top: 2px;
}


button:focus {
    outline: none;
}


.row >* {
    position: relative;
}

a{
    &:focus{
        outline: none;
    }
}
