//
// type.scss
//

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 10px 0;
  // font-family: $font-family-secondary;
  font-family: $Legend-Medium-Font;
  // color: $text-color-h;
  color: #000000;
}

.text-SemiBold {
  font-family: $Legend-SemiBold-Font;
}

.text-Light {
  font-family: $Legend-Light-Font;
}

.text-Regular {
  font-family: $Legend-Regular-Font;
}

.text-Medium {
  font-family: $Legend-Medium-Font;
}

.text-danger {
  color: red !important;
  font-family: $Legend-Regular-Font;
}

.danger-border {
  border: 1px solid #ff0000 !important;
}

.text-Bold {
  font-family: $Legend-Bold-Font;
}

.color-green {
  color: #0fb709;
}

.color-red {
  color: red;
}
.hoverColor:hover {
  background-color: #ebe8e8;
}

.color-black {
  color: #000000 !important;
}

.color-light-text {
  color: #8c8c8c;
}

.color-light-grey {
  color: #b4b4b4 !important;
}
.color-white {
  color: #ffffff;
}

.color-gray {
  color: #b4b4b4;
}

.color-bg-black {
  background-color: #000000;
}

.color-bg-white {
  background: #ffffff;
}

.color-bg-nav-header {
  background-color: #f0f4f8;
}

.tableHead {
  @extend .font-legend;
  @extend .color-black;
  border-radius: 8px;
  background-color: #f0f0f0;
}

.border-0 {
  border: 0px;
}

.button-hover-black:hover {
  background-color: #111111;
  color: #ece9e9;
}

.button-hover-black:focus {
  background-color: #111111;
  color: #ece9e9;
}

.button-hover-gray:hover {
  background-color: #f8f9fa;
  color: #0fb709;
}

.line-bottom-3 {
  margin-bottom: 3px;
}

.font-size-09 {
  font-size: 0.9rem;
}

.font-size-08 {
  font-size: 0.8rem;
}
.font-size-07 {
  font-size: 0.7rem;
}

.font-size-1 {
  font-size: 1rem;
}

.font-size-1-1 {
  font-size: 1.1rem;
}

.font-size-1-2 {
  font-size: 1.2rem;
}

.font-size-1-3 {
  font-size: 1.3rem;
}

.font-size-1-4 {
  font-size: 1.4rem;
}

.font-size-1-5 {
  font-size: 1.5rem;
}

.font-size-2 {
  font-size: 2rem;
}
.font-size-0-800 {
  font-size: 0.8rem;
}
.font-size-0-700 {
  font-size: 0.7rem;
}

.font-width-10rem {
  width: 10rem;
}

.datepick {
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 0.46rem;
}

.pac-container {
  z-index: 10000 !important;
}

.toast-no-display {
  display: none;
}

.toast-display {
  display: flex;
}

//Text Align
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

//tooltip hover
.before {
  white-space: nowrap;
  width: 10em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.after {
  cursor: pointer;
  border: 1px solid red;
  // display:block;
  // position:absolute;
  // top:0;right:10%;
  width: 2.2em;
  height: 2.2em;
}

//color border
input[type="color"] {
  -webkit-appearance: none;
  // border: 1px solid #D8D8D8;
}

input[type="color"]::-webkit-color-swatch {
  border: 1px solid #d8d8d8;
}

.tooltip {
  width: 50%;
  visibility: hidden;
  background-color: #fff;
  padding: 20px;
  -webkit-box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.5s ease;
  position: absolute;
  top: 7em;
  left: 0;
}

.after:hover + .tooltip {
  visibility: visible;
  transition: opacity 0.5s ease;
  opacity: 1;
}

//width
@for $x from 1 through 100 {
  .w-#{$x} {
    width: #{$x} + "%";
  }
  .h-#{$x} {
    height: #{$x} + "%";
  }

  .w-vw-#{$x} {
    width: #{$x} + "vw";
  }

  .h-vh-#{$x} {
    height: #{$x} + "vh";
  }

  .wd-#{$x} {
    width: #{$x} + "dvw";
  }

  .hd-#{$x} {
    height: #{$x} + "dvh";
  }

  .fs-#{$x} {
    font-size: #{$x} + "px";
  }

  .min-w-#{$x} {
    min-width: #{$x} + "%";
  }

  .min-h-#{$x} {
    min-height: #{$x} + "%";
  }

  .min-ww-#{$x} {
    min-width: #{$x} + "vw";
  }

  .min-vh-#{$x} {
    min-height: #{$x} + "vh";
  }

  .max-w-#{$x} {
    max-width: #{$x} + "%";
  }

  .max-h-#{$x} {
    max-height: #{$x} + "%";
  }

  .min-wd-#{$x} {
    min-width: #{$x}dvw;
  }
  .min-hd-#{$x} {
    min-height: #{$x}dvh;
  }

  .max-wd-#{$x} {
    max-width: #{$x}dvw;
  }
  .max-hd-#{$x} {
    max-height: #{$x}dvh;
  }
  .radius-tl-#{$x} {
    border-top-left-radius: #{$x}px;
  }
  .radius-tr-#{$x} {
    border-top-right-radius: #{$x}px;
  }
  .radius-bl-#{$x} {
    border-bottom-left-radius: #{$x}px;
  }
  .radius-br-#{$x} {
    border-bottom-right-radius: #{$x}px;
  }
}

$weights: 300, 400, 500, 600, 700, 800, 900;

$fontSize: xx-small, x-small, smaller, small, medium, large, larger, x-large,
  xx-large, xx-large;

@each $size in $fontSize {
  .font-#{$size} {
    font-size: #{$size};
  }
}

@each $wei in $weights {
  .weight-#{$wei} {
    font-weight: #{$wei};
  }
}

.h-fit-content {
  height: fit-content;
}

.w-fit {
  width: fit-content;
}

.hide-scroll-bar::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.overflow-scroll {
  overflow: scroll;
}
.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.ql-editor {
  min-height: 150px;
}
//Min width
.min-width-15 {
  min-width: 15%;
}

.min-width-30 {
  min-width: 30%;
}

.min-width-20 {
  min-width: 20%;
}

.max-width-30 {
  max-width: 30%;
}

.max-w-100 {
  max-width: 100%;
}

.media-400-100 {
  @media screen and (max-width: 400px) {
    width: 100%;
  }
}

.max-width-30-100 {
  width: 28%;
}

@media screen and (max-width: 500px) {
  .max-width-30-100 {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .max-width-30-100 {
    width: 50%;
  }
}

@media screen and (max-width: 1050px) {
  .max-width-30-100 {
    width: 60%;
  }
}
//Min Height
.min-height-1 {
  min-height: 1rem;
}

.min-height-2 {
  min-height: 2rem;
}

.min-height-3 {
  min-height: 3rem;
}

.min-height-5 {
  min-height: 5rem;
}

.min-height-6 {
  min-height: 6rem;
}

.min-height-10 {
  min-height: 10rem;
}

.min-height-11 {
  min-height: 11rem;
}

.min-height-15 {
  min-height: 15rem;
}

.min-h-100 {
  min-height: 100%;
}

.min-h-screen {
  min-height: calc(100vh - 132px);
}

.min-h-60 {
  min-height: 60dvh;
}
//font type

.font-legend {
  font-family: "LegendRegular";
}

.font-legend-light {
  font-family: "LegendLight";
}

// Font sizes (Custom)

.fs-07 {
  font-size: 0.7rem !important;
}

.fs-5 {
  font-size: 5px !important;
}
.fs-6 {
  font-size: 6px !important;
}
.fs-7 {
  font-size: 7px !important;
}
.fs-8 {
  font-size: 8px !important;
}
.fs-9 {
  font-size: 9px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-19 {
  font-size: 19px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-medium {
  font-size: medium;
}

// Font Weight

.fw-medium {
  font-weight: $font-weight-medium;
}

.fw-semibold {
  font-weight: $font-weight-semibold;
}
.fw-100 {
  font-weight: 100;
}
.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}

.fw-bold {
  font-weight: "bold";
}

.border-none {
  border: none;
}

.border-black {
  border-color: #000000;
}

.btn-primary {
  :focus {
    border: 1px solid black;
    color: black;
  }
}

.rounded-0-5 {
  border-radius: 0.5rem;
}

.rounded-1 {
  border-radius: 1rem;
}

.rounded-1-1 {
  border-radius: 1.1rem;
}

.rounded-1-2 {
  border-radius: 1.2rem;
}

.rounded-1-5 {
  border-radius: 1.5rem;
}

.rounded-2 {
  border-radius: 2rem;
}

// text utilies
.text-unline-dashed {
  border-bottom: $gray-400 1px dashed !important;
  padding-bottom: 1px;
}

.text-underline {
  text-decoration: underline;
}

.date-picker-border {
  border: 1px solid #bdc1c5;
}

.date-picker-radius {
  border-radius: 5px;
}

.date-picker-border:focus {
  border: 1px solid #2c7efa;
}

.date-picker-border:hover {
  border: 1px solid #2c7efa;
}

//margin new
.m-0-1vw {
  margin: 0.1vw;
}

.m-0-2vw {
  margin: 0.2vw;
}

.m-0-3vw {
  margin: 0.3vw;
}

.m-0-4vw {
  margin: 0.4vw;
}

.m-0-5vw {
  margin: 0.5vw;
}

.m-0-6vw {
  margin: 0.6vw;
}

.m-0-7vw {
  margin: 0.7vw;
}

.m-0-8vw {
  margin: 0.8vw;
}

.m-0-3vw {
  margin: 0.9vw;
}

.m-1vw {
  margin: 1vw;
}

.m-1-1vw {
  margin: 1.1vw;
}

.m-1-2vw {
  margin: 1.2vw;
}

.m-1-3vw {
  margin: 1.3vw;
}

.m-1-4vw {
  margin: 1.4vw;
}

.m-1-5vw {
  margin: 1.5vw;
}

.m-1-6vw {
  margin: 1.6vw;
}

.m-1-7vw {
  margin: 1.7vw;
}

.m-1-8vw {
  margin: 1.8vw;
}

.m-1-9vw {
  margin: 1.9vw;
}

.m-2vw {
  margin: 2vw;
}

.box-border-radius-8 {
  border-radius: 8px;
}

.box-border-1 {
  border: 1px solid #efefef;
}

//password eye hover
.passowrd-hover:focus {
  border: 1px solid #2c7efa;
}

.passowrd-hover:hover {
  border: 1px solid #2c7efa;
}

.get-scroll-y:hover {
  overflow-y: auto;
}

.get-scroll-x {
  overflow-x: hidden;
}

.get-scroll-x {
  overflow-y: hidden;
}

.get-scroll-x:hover {
  overflow-x: auto;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #d0d6db;
}

.new-select-border > .react-select__control {
  border: 0px !important;
}

.no-select-dropdown-icon
  > .react-select__control
  .react-select__indicators
  .react-select__dropdown-indicator
  svg {
  display: none !important;
}

.new-select-drop-style
  > .react-select__control
  .react-select__value-container--has-value
  .react-select__single-value {
  font-family: $Legend-Regular-Font !important;
  color: #000000 !important;
  font-size: 1.4rem;
}

.select-svg-disabled > .css-tj5bde-Svg {
  display: none !important;
}

.select-container-border {
  border: 1px solid #ced4da !important;
  border-radius: 5px !important;
}

.new-select-drop-style
  > .react-select__control
  .react-select__indicators
  .react-select__dropdown-indicator {
  color: #0fb709;
}

.text-limit {
  width: 8em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.table-head-bg-color {
  background-color: #f0f0f0 !important;
}

.custInput {
  position: relative;
  flex: 1 1 auto;
  min-width: 0;
  border: 1px solid rgb(216, 216, 216);
  border-radius: 6px;
  font-family: "LegendRegular";
}

.custInput:disabled {
  color: #8c8c8c;
}

.pac-container,
.pac-item {
  z-index: 2147483647 !important;
}

// input[type="radio"] {
//   appearance: none;
// }

.disabled-color-black:disabled {
  color: black !important;
}

.cell-breakWord-2 {
  word-wrap: break-word !important;
  max-width: 2px !important;
}

.react-datepicker {
  font-family: "LegendRegular" !important;
}

input:disabled {
  color: rgba(0, 0, 0, 0.38) !important;
}

.hidden-xs {
  @media screen and (max-width: 575px) {
    display: none;
  }
}

.table-border {
  border: "1px solid  #000029";
  border-radius: 10px;
}

.notification-dropdown-item {
  margin-right: 15px;
  margin-left: 5px;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
}

.notification-dropdown-item:hover {
  scale: 102%;
  transition-duration: 0.2s;
}

.capitalize-first {
  // text-transform: uppercase;
  text-transform: capitalize;
}

.capitalize {
  text-transform: uppercase;
}

//Google Maps Polygon Popup Styles ----> DO NOT CHANGE
.popup-bubble {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  font-family: sans-serif;
  overflow-y: auto;
  max-height: 60px;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.5);
}

/* The parent of the bubble. A zero-height div at the top of the tip. */
.popup-bubble-anchor {
  position: absolute;
  width: 100%;
  bottom: 8px;
  left: 0;
}

/* This element draws the tip. */
.popup-bubble-anchor::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, 0);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid white;
}

.popup-container {
  cursor: auto;
  height: 0;
  position: absolute;
  width: 200px;
}

.create-order-menu .react-select-container .react-select__menu-list {
  max-height: 180px;
}

.location-address-toolbar .ql-toolbar {
  display: none;
}
.location-address-toolbar .ql-container {
  border-top: 1px solid #ccc !important;
}
